import React, { useMemo, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import { useStore } from '../../../../context';

import defaultStyle from './defaultStyle';
import nightMode from './nightMode';

const Map = () => {
  const [{ theme }] = useStore();

  const renderMarkers = useCallback(
    (map, maps) => new maps.Marker({
      position: {
        lat: 51.921646,
        lng: 6.105339,
      },
      map,
    }),
    [],
  );

  return useMemo(
    () => (
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API }}
          defaultCenter={{
            lat: 51.921646,
            lng: 6.105339,
          }}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          options={() => ({
            mapTypeControl: false,
            clickableIcons: false,
            styles: [...defaultStyle, ...(theme !== 'dark' ? [] : nightMode)],
          })}
        />
      </div>
    ),
    [theme, renderMarkers],
  );
};

export default Map;
