import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputText, TextArea, useForm } from '../../../Form';

import fieldDefinition from '../../../../helpers/contact/fields';
import sendForm from '../../../../helpers/contact/sendForm';

const Form = () => {
  const { t } = useTranslation('componentContact');

  const [{ fields, status, sending }, {
    handleChange, handleFocus, handleBlur, handleSubmit,
  }] = useForm(fieldDefinition({ t }), sendForm);

  return status === true ? (
    <>
      <h2>{t('thanks')}</h2>
      <span>{t('success')}</span>
    </>
  ) : (
    <form className="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      {status === false && <span className="error">{t('error')}</span>}
      <div className="fields">
        <InputText
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readonly={sending}
          {...fields.name} // eslint-disable-line react/jsx-props-no-spreading
        />
        <InputText
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readonly={sending}
          {...fields.email} // eslint-disable-line react/jsx-props-no-spreading
        />
        <InputText
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readonly={sending}
          {...fields.subject} // eslint-disable-line react/jsx-props-no-spreading
        />
        <TextArea
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readonly={sending}
          {...fields.message} // eslint-disable-line react/jsx-props-no-spreading
        />
      </div>
      <input type="text" defaultValue="" name="_q" />
      <button className="action-button" type="submit" disabled={sending}>
        {t('send')}
      </button>
    </form>
  );
};

export default Form;
