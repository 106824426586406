import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../components/Layout';
import Cover from '../../components/Elements/Cover';

import PageSection from '../../components/Elements/PageSection';
import Row, { Col } from '../../components/Elements/Grid';
import Form from '../../components/Pages/Contact/Form';
import Map from '../../components/Pages/Contact/Map';
import withTranslations from '../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('contact');

  return (
    <Layout className="contact">
      <Seo title="Contact" />
      <Cover>
        <PageSection grid>
          <Row>
            <Col>
              <h1>{t('title')}</h1>
              <Map />
              <div className="contact-details">
                <div>
                  <strong>{t('address')}</strong>
                  <a
                    href="https://www.google.com/maps?daddr=Exa+55+Zevenaar+Nederland"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Exa 55, 6902 KH</span>
                    <span>Zevenaar, Nederland</span>
                  </a>
                </div>
                <div>
                  <strong>{t('telephone')}</strong>
                  <a href="tel:+31882344888">
                    <span>+31 (0)88 23 44 888</span>
                  </a>
                </div>
                <div>
                  <strong>{t('bankDetails')}</strong>
                  <span>IBAN: NL18 RABO 0384 3908 46</span>
                  <span>BIC: RABONL2U</span>
                </div>
                <div>
                  <strong>{t('emailAddresses.title')}</strong>
                  <span>
                    {`${t('emailAddresses.general')}: `}
                    <a href="mailto:info@exonet.nl">info@exonet.nl</a>
                  </span>
                  <span>
                    {`${t('emailAddresses.support')}: `}
                    <a href="mailto:support@exonet.nl">support@exonet.nl</a>
                  </span>
                  <span>
                    {`${t('emailAddresses.sales')}: `}
                    <a href="mailto:sales@exonet.nl">sales@exonet.nl</a>
                  </span>
                </div>
                <div>
                  <strong>{t('companyDetails.title')}</strong>
                  <span>{`${t('companyDetails.chamberOfCommerce')} 09129344`}</span>
                  <span>{`${t('companyDetails.vatNr')} NL817909989B01`}</span>
                </div>
              </div>
            </Col>
            <Col>
              <h1>{t('common:getReady')}</h1>
              <p>{t('formIntro')}</p>
              <Form />
            </Col>
          </Row>
        </PageSection>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
