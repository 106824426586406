import { isRequired, isEmail } from '../../components/Form/Validation';

/**
 * Get the field definition for the contact form.
 *
 * @param {Function} t The i18n translation instance.
 * @param {string} subject The subject for the email.
 *
 * @return {Object} The fields.
 */
export default ({ t, subject = '' } = {}) => ({
  name: {
    name: 'name',
    label: t('componentContact:fields.name'),
    type: 'text',
    value: '',
    rules: [
      {
        validator: isRequired,
        message: t('componentContact:rules.name.required'),
      },
    ],
  },
  subject: {
    name: 'subject',
    label: t('componentContact:fields.subject'),
    type: 'text',
    value: subject,
  },
  email: {
    name: 'email',
    label: t('componentContact:fields.email'),
    type: 'email',
    value: '',
    rules: [
      {
        validator: isRequired,
        message: t('componentContact:rules.email.required'),
      },
      {
        validator: isEmail,
        message: t('componentContact:rules.email.email'),
      },
    ],
  },
  message: {
    name: 'message',
    label: t('componentContact:fields.message'),
    value: '',
    rules: [
      {
        validator: isRequired,
        message: t('componentContact:rules.message.required'),
      },
    ],
  },
});
